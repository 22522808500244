
  import { Component, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { Claims } from '@/ts/constants/claims'
  import { Crud } from '@/ts/enums/crud'
  import { RoleType } from '@/ts/enums/roleType'
  import { Route } from '@/ts/models/route'
  import { Routes } from '@/ts/constants/routes'
  import { SearchState } from '@/ts/states/search/searchState'
  import { ToolboxState } from '@/ts/states/toolbox/toolboxState'
  import { VerificationType } from '@/ts/enums/verificationType'

  import BoxLink from '@/components/links/BoxLink.vue'

  @Component({
    components: {
      BoxLink
    }
  })
  export default class HomePanel extends Vue {

    /** PRIVATE PROPERTIES */

    private authState = new AuthState(this.$store)
    private searchState = new SearchState(this.$store)
    private toolboxState = new ToolboxState(this.$store)


    /** LIFECYCLE */

    public mounted() {
      if (this.isAdmin) {
        this.toolboxState.clear()
        this.toolboxState.fetch()
      }
    }


    /** COMPUTED PROPERTIES */

    protected get status() {
      return this.toolboxState.verifyState.status
    }

    protected get canMarket() {
      return this.user.hasPermission(Claims.MARKETING, Crud.READ)
    }

    protected get canReport() {
      return this.user.hasPermission(Claims.REPORTING, Crud.READ)
    }

    protected get canSearch() {
      return this.user.hasPermission(Claims.TOOLBOX_SEARCH, Crud.READ) || this.user.hasPermission(Claims.PATRON_SEARCH, Crud.READ)
    }

    protected get canVerify() {
      return this.user.hasPermission(Claims.TOOLBOX_VERIFY, Crud.READ)
    }

    protected get isAdmin() {
      return this.user.isInRole(RoleType.ADMIN)
    }

    protected get user() {
      return this.authState.user
    }


    /** EVENTS */

    protected onSearch() {
      this.searchState.clear()

      this.searchState.pushRoute(new Route(Routes.HOME))
      this.$router.push({ name: (this.isAdmin) ? Routes.TOOLBOX_SEARCH : Routes.PATRON_SEARCH })
    }

    protected async onVerify() {
      this.toolboxState.pushRoute(new Route(Routes.HOME))
      this.toolboxState.mode = VerificationType.VERIFY

      const person = this.toolboxState.verifications[0].original
      this.toolboxState.person.setPerson(person)

      if (this.toolboxState.hasState(VerificationType.VERIFY)) {
        this.$router.push({ name: Routes.VERIFY })
      }
      else {
        this.$router.push({ name: Routes.TOOLBOX })
      }
    }
  }
