
  import { Component, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { AuthStatus } from '@/ts/enums/authStatus'
  import { PasswordMode } from '@/ts/enums/passwordMode'
  import { Routes } from '@/ts/constants/routes'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import AccessCodeForm from './components/AccessCodeForm.vue'
  import ChangePassword from '@/components/change-password/ChangePassword.vue'
  import PasswordForm from './components/PasswordForm.vue'


  @Component({
    components: {
      AccessCodeForm,
      ChangePassword,
      PasswordForm
    }
  })
  export default class Password extends Vue {

    /* PRIVATE PROPERTIES */

    protected errorMessage = ''
    protected passwordMode = PasswordMode.RESET

    private state = new AuthState(this.$store)



    /* COMPUTED PROPERTIES */

    public get isAnonymous() {
      return this.state.authentication == AuthStatus.NONE
    }

    public get isLoggedIn() {
      return this.state.authentication == AuthStatus.LOGGED_IN
    }

    public get isInvalid() {
      return this.state.status == ViewStatus.FAILED
    }

    public get isValidated() {
      return this.state.authentication == AuthStatus.VALIDATED
    }

    public get isValidating() {
      return this.state.authentication == AuthStatus.VERIFYING
    }

    public get phone() {
      return this.state.phone;
    }

    public get title() {
      return this.isValidating ? 'Details Sent!' : 'Recover Password'
    }

    public get isSuccessful() {
      return this.status == ViewStatus.SUCCEEDED
    }

    public get number() {
      if (this.state.phone != null) {
        return `${'*'.repeat(this.state.phone.length - 6)}${this.state.phone.substr(this.state.phone.length - 4)}`
      }

      return ''
    }

    public get status() {
      return this.state.status
    }

    public get username() {
      return this.state.username
    }
 

    /* EVENTS */

    protected async onForgotPassword() {
      await this.state.forgotPassword()
      if (this.isInvalid) {
        this.errorMessage = 'This username was not recognised'
      }
    }

    protected onPasswordChanged(isPasswordChanged: boolean) {
      if (isPasswordChanged) {
        this.state.isAuthenticated = true
        this.$router.push({ name: Routes.HOME })
      }
      else {
        this.errorMessage = 'Your new password could not be saved'
      }
    }

    protected async onValidate(code: number, isValidated: boolean) {
      if (isValidated) {
        await this.state.loginCode(code)
        this.state.isValidated = true
      }
      else {
        this.errorMessage = 'Please enter the code again'
      }
    }
  }

