
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { AuthState } from '@/ts/states/auth/authState'
  import { IValidationState } from '@/ts/vee-validate/'
  import { ViewStatus } from '@/ts/enums/viewStatus'


  @Component
  export default class AccessCodeForm extends Vue {


    /** PROPERTIES */

    @Prop({ default: true })
    public shouldDelete!: boolean

    @Prop()
    public state!: AuthState


    /** PRIVATE PROPERTIES */

    protected digits: string[] = ['', '', '', '', '', '']



    /** COMPUTED PROPERIES */

    protected get accessCode() {
      return this.digits.join('')
    }

    protected get enabled() {
      return this.accessCode.length == 6
    }

    protected get status() {
      return this.state.status
    }

    protected get submitText() {
      return (this.status == ViewStatus.IN_PROGRESS) ? 'Verifying...' : 'Verify'
    }

    /** EVENTS */

    protected onInput(index: number, value: string) {
      if (value.length <= 0) {
        return
      }
      else if (isNaN(Number(value))) {
        this.digits[index] = ''
      }
      else {
        if (index < 5) {
          document.getElementById(`digit${index + 1}`)?.focus()
        }
      }
    }

    protected async onResend() {
      await this.state.resendCode()
    }

    protected async onSubmit() {
      await this.state.validate(this.accessCode, this.shouldDelete)

      if (this.status == ViewStatus.SUCCEEDED) {
        this.$emit('validate', this.accessCode, true)
      }
      else if (this.status == ViewStatus.FAILED) {
        this.digits = ['', '', '', '', '', '']
        this.$emit('validate', null, false)
      }
    }


    /** PRIVATE METHODS */

    protected getValidationState(state: IValidationState) {
      return state.dirty || state.validated ? state.valid : null
    }

  }
