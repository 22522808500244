
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { AuthState } from '@/ts/states/auth/authState'
  import { IValidationState } from '@/ts/vee-validate'
  import { PasswordMode } from '@/ts/enums/passwordMode'
  import { ValidationObserver } from 'vee-validate'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  @Component
  export default class ChangePassword extends Vue {

    /* PROPERTIES */

    
    @Prop({ default: PasswordMode.CHANGE })
    public mode!: PasswordMode

    @Prop()
    public state!: AuthState


    /** PRIVATE PROPERTIES */

    protected confirmPassword = ''
    

    /** COMPUTED PROPERTIES */

    protected get isChanging() {
      return this.mode == PasswordMode.CHANGE
    }

    protected get isUpdating() {
      return this.state.status == ViewStatus.IN_PROGRESS
    }

    protected get status() {
      return this.state.status
    }

    protected get submitText() {
      return this.isUpdating ? 'Updating...' : 'Update password'
    }

    /** EVENTS */

    protected async onSubmit() {
      await this.state.changePassword(this.mode)
      this.confirmPassword = ''

      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
      observer?.reset()

      this.$emit('submit', this.status == ViewStatus.SUCCEEDED)
    }


    /** PRIVATE METHODS */

    protected getValidationState(state: IValidationState) {
      return state.dirty || state.validated ? state.valid : null
    }

  }

