
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  @Component

  export default class BoxLink extends Vue {

    /** PROPERTIES */

    @Prop({ default: 0 })
    public badge!: number

    @Prop({ default: false })
    public disabled!: boolean

    @Prop({ default: '' })
    public icon!: string

    @Prop({ default: ViewStatus.NONE })
    public status!: ViewStatus

    @Prop({ default: null })
    public to!: string | null

    @Prop({ default: 'Primary' })
    public variant!: string


    /** COMPUTED PROPERTIES */

    protected get isLoading() {
      return this.status == ViewStatus.IN_PROGRESS
    }

    /** EVENTS */

    protected onClick() {
      this.$emit('click')
    }
  }

