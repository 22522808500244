
  import { Component, Vue } from 'vue-property-decorator';

  import HomePanel from './components/HomePanel.vue'


  @Component({
    components: {
      HomePanel,
    }
  })
  export default class Home extends Vue {}

