
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { AuthState } from '@/ts/states/auth/authState'
  import { IValidationState } from '@/ts/vee-validate/'
  import { ViewStatus } from '@/ts/enums/viewStatus'


  @Component
  export default class LoginForm extends Vue {


    /** PROPERTIES */

    @Prop()
    public state!: AuthState


    /** COMPUTED PROPERIES */


    protected get status() {
      return this.state.status
    }

    protected get submitText() {
      return (this.status == ViewStatus.IN_PROGRESS) ? 'Logging in...' : 'Log in'
    }

    /** EVENTS */

    protected async onSubmit() {
      await this.state.login()
      this.$emit('submit', this.status == ViewStatus.SUCCEEDED)
    }


    /** PRIVATE METHODS */

    protected getValidationState(state: IValidationState) {
      return state.dirty || state.validated ? state.valid : null
    }

  }
