
  import { Component, Vue } from 'vue-property-decorator'
  import { AuthState } from '@/ts/states/auth/authState'
  import { AuthStatus } from '@/ts/enums/authStatus'
  import { PasswordMode } from '@/ts/enums/passwordMode'
  import { Routes } from '@/ts/constants/routes'
  import { ViewStatus } from '@/ts/enums/viewStatus'

  import AccessCodeForm from './components/AccessCodeForm.vue'
  import ChangePassword from '@/components/change-password/ChangePassword.vue'
  import LoginForm from './components/LoginForm.vue'


  @Component({
    components: {
      AccessCodeForm,
      ChangePassword,
      LoginForm,
    }
  })
  export default class Login extends Vue {

    /* PRIVATE PROPERTIES */

    protected errorMessage = ''
    protected isPasswordChangeRequired = false
    protected passwordMode = PasswordMode.RESET

    private state = new AuthState(this.$store)



    /* COMPUTED PROPERTIES */

    public get isAnonymous() {
      return this.state.authentication == AuthStatus.NONE
    }

    public get isLoggedIn() {
      return this.state.authentication == AuthStatus.LOGGED_IN
    }

    public get isInvalid() {
      return this.state.status == ViewStatus.FAILED
    }

    public get isValidated() {
      return this.state.authentication == AuthStatus.VALIDATED
    }

    public get status() {
      return this.state.status
    }

    /** EVENTS */

    protected onLoggedIn(isLoggedIn: boolean) {
      if (isLoggedIn) {
        this.isPasswordChangeRequired = this.state.user.passwordResetRequired
        this.state.isAuthenticated = true
      }
      else {
        this.errorMessage = 'Username &/or password is incorrect'
      }
    }

    protected onPasswordChanged(isPasswordChanged: boolean) {
      this.isPasswordChangeRequired = !isPasswordChanged
      if (isPasswordChanged) {
        this.$router.push({ name: Routes.HOME })
      }
      else {
        this.errorMessage = 'Your password could not be changed'
      }

    }

    protected onValidate(code: number, isValidated: boolean) {
      if (isValidated) {
        this.state.isValidated = true
        if (!this.isPasswordChangeRequired) {
          this.$router.push({ name: Routes.HOME })
        }
      }
      else {
        this.errorMessage = 'The code you entered is incorrect'
      }
    }
  }

